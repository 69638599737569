var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("试卷管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-input",
            {
              staticClass: "tb-search-input",
              attrs: { placeholder: "输入搜索试卷名称、id" },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.queryData.inputVal,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.queryData,
                    "inputVal",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "queryData.inputVal",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { slot: "prepend", placeholder: "请选择" },
                  slot: "prepend",
                  model: {
                    value: _vm.queryData.select,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryData, "select", $$v)
                    },
                    expression: "queryData.select",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "试卷名称", value: "1" } }),
                  _c("el-option", { attrs: { label: "试卷id", value: "2" } }),
                ],
                1
              ),
              _c("i", {
                staticClass: "el-input__icon el-icon-search",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
            ],
            1
          ),
          _c("el-input", {
            staticClass: "tb-sm-input",
            attrs: { placeholder: "请输入课程名称" },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.search.apply(null, arguments)
              },
            },
            model: {
              value: _vm.queryData.lessonVal,
              callback: function ($$v) {
                _vm.$set(_vm.queryData, "lessonVal", $$v)
              },
              expression: "queryData.lessonVal",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择试卷状态" },
              model: {
                value: _vm.queryData.selectStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.queryData, "selectStatus", $$v)
                },
                expression: "queryData.selectStatus",
              },
            },
            _vm._l(_vm.queryOption, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.search()
                },
              },
            },
            [_vm._v("查 询")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.reset()
                },
              },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "exam:ManageAdd",
                  expression: "'exam:ManageAdd'",
                },
              ],
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.addPaper()
                },
              },
            },
            [_vm._v("新 建")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "试卷id", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("uuidFormat")(scope.row.id))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "left", label: "试卷名称", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "title-link",
                        on: {
                          click: function ($event) {
                            return _vm.previewPaper(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.title))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "类别名称", align: "left" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.catalogName) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "课程/课节/任务", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.courseTitle
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.courseTitle) + "/"),
                        ])
                      : _c("span", [_vm._v("无课程/")]),
                    scope.row.courseUnitTitle
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.courseUnitTitle) + "/"),
                        ])
                      : _c("span", [_vm._v("无课节/")]),
                    scope.row.taskList.length !== 0
                      ? _c(
                          "span",
                          _vm._l(scope.row.taskList, function (item) {
                            return _c("span", { key: item.id }, [
                              _vm._v(_vm._s(item.title)),
                            ])
                          }),
                          0
                        )
                      : _c("span", [_vm._v("无任务")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "题目数量", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.questionCount))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建人", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.creatorInfo !== null
                      ? _c("span", [_vm._v(_vm._s(scope.row.creatorInfo.name))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style:
                          "color" +
                          ":" +
                          _vm.getStateLessonColor(scope.row.status),
                      },
                      [_vm._v(_vm._s(scope.row.status))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "答题时长/mins", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.limitTime === 0
                      ? _c("span", [_vm._v("不限时")])
                      : _c("span", [_vm._v(_vm._s(scope.row.limitTime))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "创建时间", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("dateFormat")(scope.row.createTime))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-dropdown",
                      { attrs: { trigger: "click" } },
                      [
                        _c(
                          "el-button",
                          { attrs: { type: "primary", size: "small" } },
                          [
                            _vm._v(" 管 理 "),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]
                        ),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "exam:ManageEdit",
                                    expression: "'exam:ManageEdit'",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.editPaper(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("编辑试卷")])]
                            ),
                            scope.row.status === "未发布"
                              ? [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "has",
                                          rawName: "v-has",
                                          value: "exam:ManagePub",
                                          expression: "'exam:ManagePub'",
                                        },
                                      ],
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.publicPaper(scope.row)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("发布试卷")])]
                                  ),
                                ]
                              : _vm._e(),
                            scope.row.status === "已发布"
                              ? [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      directives: [
                                        {
                                          name: "has",
                                          rawName: "v-has",
                                          value: "exam:ManagePause",
                                          expression: "'exam:ManagePause'",
                                        },
                                      ],
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.pausePaper(scope.row)
                                        },
                                      },
                                    },
                                    [_c("span", [_vm._v("停用试卷")])]
                                  ),
                                ]
                              : _vm._e(),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.copyPaper(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("复制试卷")])]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                directives: [
                                  {
                                    name: "has",
                                    rawName: "v-has",
                                    value: "exam:ManageDel",
                                    expression: "'exam:ManageDel'",
                                  },
                                ],
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.delPaper(scope.row)
                                  },
                                },
                              },
                              [_c("span", [_vm._v("删除试卷")])]
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-paper", {
        attrs: {
          show: _vm.paperDialog,
          isPublish: false,
          isCopy: _vm.isCopy,
          editPaperData: _vm.editPaperData,
        },
        on: {
          close: function ($event) {
            _vm.paperDialog = false
            _vm.editPaperData = null
            _vm.isCopy = false
          },
          addData: _vm.addData,
          editData: _vm.editData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }